.sobre-mim {
    display: flex;
    width: 100%;
    height: 441px;
    justify-content: center;
    align-items: center;
    gap: 32px;
    flex-shrink: 0;
    margin-bottom: 210px;
}

.texto-sobre-mim {
    margin-right: 400px;
}

.name-sobre {
    height: 82px;
    align-self: stretch;
    color: #000;
    font-family: Inter;
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sobre-sobre {
    height: 28px;
    align-self: stretch;
    color: #000;
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.p-sobre {
    height: 181px;
    width: 600px;
    align-self: stretch;
    color: #000;
    text-align: justify;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.profile-pic {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    margin-right: 50px;
    margin-left: 400px;
}

.container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 32px;
    flex-shrink: 0;
    margin: 200px 0 0 0;
}

h1 {
    margin-bottom: 10px;
}

h2 {
    margin-bottom: 20px;
}

section {
    max-width: 900px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    height: auto;
}

@media (max-width: 768px) {
    .sobre-mim {
        flex-direction: column;
        height: auto;
        margin-bottom: 100px;
    }

    .texto-sobre-mim {
        margin-right: 0;
        margin-top: 20px;
        text-align: center;
    }

    .name-sobre {
        font-size: 50px;
        text-align: center;
    }

    .sobre-sobre {
        font-size: 22px;
        text-align: center;
    }

    .p-sobre {
        width: 100%;
        font-size: 18px;
        text-align: center;
        padding: 0 20px;
    }

    .profile-pic {
        width: 200px;
        height: 200px;
        margin-left: 0;
        margin-right: 0;
    }

    .container {
        flex-direction: column;
        margin: 100px 0 0 0;
    }

    section {
        width: 100%;
        margin: 20px auto;
        padding: 15px;
    }

    h1,
    h2 {
        text-align: center;
    }
}