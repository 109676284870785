.footer {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-top: 1px solid #5a5a5a;
}

.footer p {
  margin: 0;
}

.footer a {
  text-decoration: none;
  color: #000;
}

.contact-info,
.github {
  text-align: left;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .contact-info,
  .github {
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
  }

  .footer p {
    margin-bottom: 10px;
  }
}