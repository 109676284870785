.project {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
}

.project p {
    margin-left: 100px;
    text-align: justify;
}

.project ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    color: #666;
}

.project ul li {
    margin-bottom: 5px;
    flex: 1;
    display: flex;
    align-items: center;
}

.project ul li a {
    text-decoration: none;
    color: #0066cc;
    flex: 1;
    margin-right: 250px;
    max-width: 250px;
    margin-left: 15px;
}

h2 {
    margin-top: 50px;
}

button {
    background-color: transparent;
    border: none;
    color: #007bff;
    font-size: 16px;
    cursor: pointer;
    display: block;
    margin: 20px auto;
    margin-top: 200px;
}

button:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .project {
        flex-direction: column;
        align-items: flex-start;
    }

    .project p {
        margin-left: 0;
        margin-top: 20px;
    }

    .project ul li a {
        margin-right: 0;
        max-width: 100%;
        margin-left: 0;
    }

    button {
        margin-top: 50px;
    }
}