.estudos {
    width: 968px;
    height: auto;
    flex-shrink: 0;
    margin-bottom: 100px;
}

section ul li {
    text-decoration: none;
    list-style-type: none;
}

.estudos ul li p {
    line-height: 1.5;
    margin-bottom: 15px;
}

.estudos p strong {
    display: inline-block;
    width: 250px;
}

button {
    background-color: transparent;
    border: none;
    color: #007bff;
    font-size: 16px;
    cursor: pointer;
    display: block;
    margin: 20px auto;
    margin-top: 200px;
}

button:hover {
    text-decoration: underline;
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
    .estudos {
        width: 100%;
        margin-bottom: 50px;
    }

    .estudos p strong {
        width: 100%;
    }

    button {
        margin-top: 50px;
    }
}