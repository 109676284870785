.contato {
    width: 968px;
    height: auto;
    flex-shrink: 0;
    margin-bottom: 100px;
}

.contato-full {
    margin-bottom: 300px;
}

.contato h3 strong {
    display: inline-block;
    width: 250px;
}

.contato a {
    color: #000;
}

button {
    background-color: transparent;
    border: none;
    color: #007bff;
    font-size: 16px;
    cursor: pointer;
    display: block;
    margin: 20px auto;
    margin-top: 310px;
}

button:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .contato {
        width: 100%;
        margin-bottom: 50px;
    }

    .contato h3 strong {
        width: 100%;
    }

    button {
        margin-top: 50px;
    }
}