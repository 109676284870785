.header {
  display: flex;
  padding: 24px 50px;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.circle {
  width: 50px;
  height: 50px;
  background-color: green;
  border-radius: 50%;
  margin-right: 10px;
}

.name {
  width: 214px;
  height: 35px;
  color: #000;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

nav ul {
  display: flex;
  list-style: none;
}

nav ul li {
  margin-left: 20px;
  text-decoration: none;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .logo {
    margin-bottom: 15px;
  }

  .name {
    width: 100%;
    font-size: 22px;
  }

  nav ul {
    flex-direction: column;
    width: 100%;
  }

  nav ul li {
    margin-left: 0;
    margin-bottom: 10px;
  }
}